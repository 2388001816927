// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-begin-js": () => import("./../src/pages/begin.js" /* webpackChunkName: "component---src-pages-begin-js" */),
  "component---src-pages-duk-js": () => import("./../src/pages/duk.js" /* webpackChunkName: "component---src-pages-duk-js" */),
  "component---src-pages-education-js": () => import("./../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-exam-start-js": () => import("./../src/pages/exam-start.js" /* webpackChunkName: "component---src-pages-exam-start-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modiseja-treniruote-js": () => import("./../src/pages/modiseja-treniruote.js" /* webpackChunkName: "component---src-pages-modiseja-treniruote-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-prizes-js": () => import("./../src/pages/prizes.js" /* webpackChunkName: "component---src-pages-prizes-js" */),
  "component---src-pages-registration-js": () => import("./../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-registration-success-js": () => import("./../src/pages/registration-success.js" /* webpackChunkName: "component---src-pages-registration-success-js" */),
  "component---src-pages-registration-verification-js": () => import("./../src/pages/registration-verification.js" /* webpackChunkName: "component---src-pages-registration-verification-js" */),
  "component---src-pages-rules-js": () => import("./../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-start-js": () => import("./../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-try-begin-js": () => import("./../src/pages/try-begin.js" /* webpackChunkName: "component---src-pages-try-begin-js" */),
  "component---src-pages-try-test-js": () => import("./../src/pages/try-test.js" /* webpackChunkName: "component---src-pages-try-test-js" */)
}

